<template>
    <div class="connection-page">
      <div class="text-section">
        <h1>เชื่อมต่อไม่สำเร็จ...</h1>
        <p>กำลังกลับเข้าสู่ระบบใน 5 วินาที โปรดลองใหม่อีกครั้ง</p>
      </div>
      <div class="image-section">
        <img src="/img/fail.png" alt="Success" class="success-image" />
      </div>
    </div>
</template>
  
<script>
  export default {
    mounted() {
      setTimeout(() => {
        this.redirect();
      }, 5000); 
    },
    methods: {
      redirect() {
        this.$router.push('/ecommerce/ecommerceSetting'); 
      }
    }
  };
</script>
  
<style scoped>
  .connection-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    padding: 0 50px;
    background-color: white;
  }
  
  .text-section {
    flex: 1;
    margin-left: 20%;
  }
  
  .text-section h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .text-section p {
    font-size: 1.25rem;
    color: #777;
  }
  
  .image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

  }
</style>
  